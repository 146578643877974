<template>
  <section class="ma-4" name="modulos">
    <v-row>
      <v-col
        sm="6"
        md="4"
        cols="12"
        v-for="item in modulos"
        :key="item.routeName"
        :value="`item-${item.routeName}`"
      >
        <CardModules :item="item"></CardModules>
      </v-col>
    </v-row>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="logotipo" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
  </section>
</template>

<script>
import logo from "@/assets/images/logos/legiio.png";
import CardModules from "./modulos/components/CardModules.vue";
export default {
  name: "Modulos",
  components: { CardModules },
  data() {
    return {
      logotipo: logo,
      tab: null,
    };
  },

  computed: {
    modulos: {
      get() {
        return this.$store.getters["empresas/getModulosEmpresa"];
      },
    },
    loading: {
      get() {
        return this.$store.getters["empresas/getLoading"];
      },
      set(value) {
        return value;
      },
    },
  },

  created() {},

  methods: {},
};
</script>

<style scoped>
.quebra-de-linha {
  word-break: break-word;
}

.transform-personalizado {
  transition: all 0.3s;
  cursor: pointer;
  min-height: 132px;
}

.transform-personalizado:hover {
  transform: scale(1.1);
}
</style>
